import { DataAccess, ResponseMessage } from "../../dataaccess/data.access";
import { S25Util } from "../../util/s25-util";
import { jSith } from "../../util/jquery-replacement";

export interface MatchStudentHousing {
    bldgId?: number;
    seasonId?: number;
    building?: BuildingDoc;
}

export interface MatchProfileDesiredBuilding {
    contId?: number;
    bldgId?: number;
    sortOrder?: number;
    building?: BuildingDoc;
}

export interface BuildingDoc {
    bldgId?: number;
    bldgName?: string;
    bldgCode?: string;
}

export interface MatchFriend {
    seasonId?: number;
    condId?: number;
    friendContId?: number;
}

export interface MatchFriendContact {
    contId?: number;
    firstName?: string;
    lastName?: string;
    friendMsg?: string; // null unless mutual
    incoming?: boolean; // initiated by another contact
    outgoing?: boolean; // initiated by calling contact
}

export interface Answer {
    answerId?: number;
    questionId?: number;
    contId?: number;
    seasonId?: number;
    answer?: string;
    longAnswer?: string;
    question?: Question;
}

export interface MatchProfile {
    contId?: number;
    contact?: DBContact;
    seasonId?: number;
    inviteHash?: string;
    friendMsg?: string;
    visible?: boolean;
    desiredBuildings?: MatchDesiredBuilding[];
    needsFirstLevel?: boolean;
    answers?: Answer[];
    matchRoommateGroupId?: number;

    // convenience for front-end only
    friend?: MatchFriendContact;
    imageUri?: string;

    [key: string]: any;
}

export interface Question {
    questionId?: number;
    question?: string;
    seasonId?: number;
    questionType?: "SELECT" | "MULTISELECT" | "TEXT";
    options?: QuestionOption[];
    sortOrder?: number;
    hidden?: boolean;

    // below are for front-end convenience and will not come from data:
    newAnswer?: {
        text?: string;
    };
    answers?: QuestionOption[];
    singleAnswer?: QuestionOption;
    longAnswer?: string;
    answerId?: number;

    [key: string]: any;
}

export interface QuestionOption {
    questionId?: number;
    option?: string;
    seasonId?: number;
    sortOrder?: number;
}

export interface MatchDesiredBuilding {
    contId?: number;
    bldgId?: number;
    sortOrder?: number;
}

export interface MatchRoommateGroup {
    matchRoommateGroupId?: number;
    name?: string;
    desiredCount?: number;
    groupInviteHash?: string;
    seasonId?: number;
    roomId?: number;
    desiredBuildings?: string;
    needsFirstLevel?: boolean;
    members?: MatchProfile[];
}

export interface MatchInvite {
    inviteId?: number;
    status?: "Pending" | "Approved" | "Denied";
    toContId?: number;
    fromContId?: number;
    matchRoommateGroupId?: number;
    seasonId?: number;
    message?: string;
    toContact?: DBContact;
    fromContact?: DBContact;
    votes?: MatchInviteVote[];
}

export interface MatchInviteVote {
    inviteId?: number;
    contId?: number;
    contact?: DBContact;
    status?: "Pending" | "Approved" | "Denied";
}

export interface DBContact {
    contId?: number;
    internalId?: string;
    firstName?: string;
    familyName?: string;
    addresses?: DBContactAddress[];
}

export interface DBContactAddress {
    contId?: number;
    addrType?: number; // 3 for work, 4 for home
    email?: string;
    phone?: string;
}

export class StudentHousingService {
    public static initialize(): Promise<ResponseMessage> {
        return DataAccess.post("/match/initialize.json");
    }

    public static addProfiles(profiles: MatchProfile[]): Promise<MatchProfile[]> {
        return DataAccess.post("/match/profiles.json", profiles);
    }

    public static async getQuestions(seasonId: number): Promise<Question[]> {
        let questions = (await DataAccess.get(`/match/questions.json?seasonId=${seasonId}`)) as Question[];
        questions.sort(S25Util.shallowSort("sortOrder", true));
        questions.forEach((q) => q.options.sort(S25Util.shallowSort("sortOrder", true)));
        return questions;
    }

    public static replaceSeasonQuestions(seasonId: number, questions: Question[]): Promise<ResponseMessage> {
        return DataAccess.post(`/match/questions.json?seasonId=${seasonId}`, questions);
    }

    public static getProfile(inviteHash: string): Promise<MatchProfile> {
        return DataAccess.get(`/match/profile/${inviteHash}.json`);
    }

    public static getStudentBuildings(seasonId: number): Promise<MatchStudentHousing[]> {
        return DataAccess.get(`/match/buildings.json?seasonId=${seasonId}`);
    }

    public static setStudentBuildings(seasonId: number, buildings: MatchStudentHousing[]): Promise<ResponseMessage> {
        return DataAccess.post(`/match/buildings.json?seasonId=${seasonId}`, buildings);
    }

    public static setProfileDesiredBuildings(
        profileInviteHash: string,
        buildings: MatchProfileDesiredBuilding[],
    ): Promise<ResponseMessage> {
        return DataAccess.post(`/match/${profileInviteHash}/buildings.json`, buildings);
    }

    public static setQuestionAnswers(
        seasonId: number,
        profileInviteHash: string,
        answers: Answer[],
    ): Promise<ResponseMessage> {
        return DataAccess.post(`/match/profile/${profileInviteHash}/answers.json?seasonId=${seasonId}`, answers);
    }

    public static updateProfile(
        profileInviteHash: string,
        contId: number,
        needsFirstLevel: boolean,
        friendMsg: string,
    ): Promise<ResponseMessage> {
        let profile: MatchProfile = { needsFirstLevel: needsFirstLevel, contId: contId, friendMsg: friendMsg };
        return DataAccess.put(`/match/profile/${profileInviteHash}.json`, profile);
    }

    public static getFriends(seasonId: number, profileInviteHash: string): Promise<MatchFriendContact[]> {
        return DataAccess.get(`/match/${profileInviteHash}/friends.json?seasonId=${seasonId}`);
    }

    public static deleteFriend(
        profileInviteHash: string,
        friendContId: number,
        seasonId: number,
    ): Promise<ResponseMessage> {
        return DataAccess.delete(`/match/${profileInviteHash}/friend/${friendContId}.json?seasonId=${seasonId}`);
    }

    public static addFriend(
        profileInviteHash: string,
        seasonId: number,
        friend: MatchFriend,
    ): Promise<ResponseMessage> {
        return DataAccess.post(`/match/${profileInviteHash}/friend.json?seasonId=${seasonId}`, friend);
    }

    public static getProfiles(
        seasonId: number,
        contId: number,
        questionId?: number,
        answer?: string,
    ): Promise<MatchProfile[]> {
        let url = `/match/${seasonId}/profiles.json?contId=${contId}`;
        if (questionId && answer) {
            answer = encodeURIComponent(answer);
            url += `&questionId=${questionId}&answer=${answer}`;
        }
        return DataAccess.get(url);
    }

    public static getRoommateGroup(matchRoommateGroupId: number): Promise<MatchRoommateGroup> {
        return DataAccess.get(`/match/group/${matchRoommateGroupId}.json`);
    }

    public static getOutgoingInvites(matchRoommateGroupId: number): Promise<MatchInvite[]> {
        if (!matchRoommateGroupId) {
            return jSith.when([]);
        }
        return DataAccess.get(`/match/group/${matchRoommateGroupId}/invites.json`);
    }

    public static getIncomingInvites(profileInviteHash: string): Promise<MatchInvite[]> {
        return DataAccess.get(`/match/profile/${profileInviteHash}/invites.json`);
    }

    public static createGroup(profileInviteHash: string, group: MatchRoommateGroup): Promise<MatchRoommateGroup> {
        return DataAccess.post(`/match/${profileInviteHash}/group.json`, group);
    }

    public static inviteFriendAsRoommate(
        profileInviteHash: string,
        groupInviteHash: string,
        toContId: number,
        seasonId: number,
    ): Promise<ResponseMessage> {
        return DataAccess.post(
            `/match/profile/${profileInviteHash}/group/${groupInviteHash}/invite.json?toContId=${toContId}&seasonId=${seasonId}`,
        );
    }

    public static uninviteFriendAsRoommate(
        profileInviteHash: string,
        groupInviteHash: string,
        toContId: number,
    ): Promise<ResponseMessage> {
        return DataAccess.delete(
            `/match/profile/${profileInviteHash}/group/${groupInviteHash}/invite.json?toContId=${toContId}`,
        );
    }

    public static acceptInvite(
        matchRoommateGroupId: number,
        fromContId: number,
        toProfileInviteHash: string,
    ): Promise<ResponseMessage> {
        return DataAccess.get(
            `/match/group/invite/accept.json?matchRoommateGroupId=${matchRoommateGroupId}&fromContId=${fromContId}&toProfileInviteHash=${toProfileInviteHash}`,
        );
    }

    public static declineInvite(matchRoommateGroupId: number, toProfileInviteHash: string): Promise<ResponseMessage> {
        return DataAccess.delete(`/match/group/${matchRoommateGroupId}/to/${toProfileInviteHash}/accept.json`);
    }

    public static leaveGroup(profileInviteHash: string, matchRoommateGroupId: number): Promise<ResponseMessage> {
        return DataAccess.delete(`/match/profile/${profileInviteHash}/group/${matchRoommateGroupId}/membership.json`);
    }

    public static processSeason(seasonId: number): Promise<ResponseMessage> {
        return DataAccess.post(`/match/${seasonId}/process.json`);
    }

    public static rollOverProfiles(fromSeasonId: number, toSeasonId: number): Promise<ResponseMessage> {
        return DataAccess.post(`/match/profiles/rollover.json?fromSeasonId=${fromSeasonId}&toSeasonId=${toSeasonId}`);
    }
}
