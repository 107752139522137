import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { StudentHousingService } from "../student.housing.service";
import { Season } from "../seasons/seasons.service";
import { S25Util } from "../../../util/s25-util";

@TypeManagerDecorator("s25-ng-season-process")
@Component({
    selector: "s25-ng-season-process",
    template: `
        <div>
            <s25-ng-season-dropdown
                class="ngBlock c-margin-bottom--single"
                [(selectedSeason)]="season"
            ></s25-ng-season-dropdown>
            @if (totalProcessed !== undefined) {
                <div>
                    <p>Total Processed: {{ totalProcessed }}</p>
                    <p>Total New: {{ totalNew }}</p>
                    <br />
                    <p>
                        To view these events, run an events search for Event Type: Student Housing and start/end dates
                        corresponding to this season.
                    </p>
                </div>
            }
            <button class="aw-button aw-button--primary c-margin-top--single" (click)="processSeason()">Process</button>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeasonProcessComponent implements OnInit {
    season: Season;
    totalProcessed: number;
    totalNew: number;

    constructor(private cd: ChangeDetectorRef) {}

    processSeason = async () => {
        if (!this.season?.seasonId) {
            alert("Please choose a season to process");
        }
        const respMsg = await StudentHousingService.processSeason(this.season.seasonId);
        console.log(respMsg);
        this.totalProcessed = respMsg.bulkInsertResponse?.outputs?.length || 0;
        this.totalNew =
            respMsg.bulkInsertResponse?.outputs?.filter((o) => S25Util.toBool(o["wasRowInserted"])).length || 0;
        if (respMsg.messageCode !== "success" && respMsg.message) {
            alert(respMsg.message);
        }
        this.cd.detectChanges();
    };

    ngOnInit() {}
}
