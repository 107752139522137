import { TypeManagerDecorator } from "../../../main/type.map.service";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { S25Util } from "../../../util/s25-util";
import { Answer, MatchProfile, MatchStudentHousing, Question, StudentHousingService } from "../student.housing.service";
import { Season, SeasonsService } from "../seasons/seasons.service";
import { ImageActionDataI, ImageUploadModelI } from "../../s25-image-upload/s25.image.upload.component";
import { AttachmentService } from "../../../services/attachment.service";
import { Rule, RuleTreeService } from "../../../services/rule.tree.service";
import { EventFormRuleUtil } from "../../s25-event-creation-form/s25.event.form.rule.util";
import { S25RuleTreeUtil } from "../../s25-rule-tree/s25.rule.tree.util";

@TypeManagerDecorator("s25-ng-match-profile")
@Component({
    selector: "s25-ng-match-profile",
    template: `
        @if (init) {
            <div class="c-margin-left--single c-margin-top--single">
                <h2 class="c-margin-bottom--single">
                    {{ profile.contact.firstName }} {{ profile.contact.familyName }}
                </h2>

                @if (profileSubmitted) {
                    <div class="ngInlineBlock c-margin-bottom--single">
                        <div class="tab">
                            <button
                                [ngClass]="{ active: mode === 'profile' }"
                                class="c-textButton"
                                (click)="setMode('profile')"
                            >
                                Profile
                            </button>
                            <button
                                [ngClass]="{ active: mode === 'friendSearch' }"
                                class="c-textButton"
                                (click)="setMode('friendSearch')"
                            >
                                Friend Search
                            </button>
                            <button
                                [ngClass]="{ active: mode === 'roommates' }"
                                class="c-textButton"
                                (click)="setMode('roommates')"
                            >
                                Roommates
                            </button>
                        </div>
                    </div>
                }

                @if (mode === "profile") {
                    <label class="c-margin-bottom--single ngBlock" for="profilePicture">Profile Picture</label>
                    <s25-ng-image-upload
                        id="profilePicture"
                        [imageUri]="imageObj.imageData"
                        [hasCropper]="true"
                        [(model)]="imageModel"
                        [options]="{ hasRotate: true, hasZoom: true }"
                        [minWidth]="100"
                        [maxWidth]="320"
                        [minHeight]="100"
                        [maxHeight]="320"
                    ></s25-ng-image-upload>

                    <button class="aw-button aw-button--outline c-margin-bottom--half" (click)="clearProfilePicture()">
                        Clear Profile Picture
                    </button>

                    <h3 class="c-margin-bottom--single">Questions</h3>
                    <div class="c-margin-bottom--single">
                        @for (question of questions; track question) {
                            <div class="c-margin-bottom--half" [hidden]="question.hidden">
                                <div>{{ question.question }}</div>

                                @if (question.questionType === "SELECT") {
                                    <select
                                        class="c-selectInput"
                                        [(ngModel)]="question.singleAnswer"
                                        (ngModelChange)="runRules()"
                                    >
                                        @for (opt of question.options; track opt) {
                                            <option [ngValue]="opt">
                                                {{ opt.option }}
                                            </option>
                                        }
                                    </select>
                                }

                                @if (question.questionType === "MULTISELECT") {
                                    <select
                                        class="c-selectInput"
                                        [(ngModel)]="question.answers"
                                        multiple="multiple"
                                        (ngModelChange)="runRules()"
                                    >
                                        @for (opt of question.options; track opt) {
                                            <option [ngValue]="opt">
                                                {{ opt.option }}
                                            </option>
                                        }
                                    </select>
                                }

                                @if (question.questionType === "TEXT") {
                                    <label
                                        >Answer: <input class="c-input" type="text" [(ngModel)]="question.longAnswer"
                                    /></label>
                                }
                            </div>
                        }
                    </div>

                    <h3 class="c-margin-bottom--single">Building Order</h3>
                    <div class="buildingContainer c-margin-bottom--single">
                        <div>Order the buildings in which you'd prefer to live.</div>
                        @if (season?.buildingMsg) {
                            <div>Additional Building Info: {{ season.buildingMsg }}</div>
                        }
                        <ul s25-ng-dnd-sortable [items]="buildings">
                            @for (building of buildings; track building; let i = $index) {
                                <li s25-ng-dnd-sortable-item [index]="i">
                                    <s25-ng-drag-handle></s25-ng-drag-handle>
                                    <div>
                                        {{ building.building.bldgName }}
                                        @if (building.building.bldgCode) {
                                            <span>({{ building.building.bldgCode }})</span>
                                        }
                                    </div>
                                </li>
                            }
                        </ul>
                    </div>

                    <label class="ngBlock c-margin-bottom--single"
                        >Custom Message for Friends (include your contact info so you can chat):
                        <textarea class="cn-form__control" [(ngModel)]="profile.friendMsg"></textarea>
                    </label>

                    <label class="ngBlock c-margin-bottom--single"
                        >I need a ground-floor room:
                        <input [(ngModel)]="profile.needsFirstLevel" type="checkbox" name="needsGroundFloor" />
                    </label>

                    <button class="aw-button aw-button--primary" (click)="save()">Save</button>
                }

                @if (mode === "friendSearch") {
                    <s25-ng-profile-list [profile]="profile"></s25-ng-profile-list>
                }

                @if (mode === "roommates") {
                    <s25-ng-roommates [profile]="profile"></s25-ng-roommates>
                }
            </div>
        }
    `,
    styles: `
        s25-ng-drag-handle {
            margin: auto 0;
        }

        .buildingContainer ul {
            padding-inline-start: 0;
        }

        .buildingContainer ul li {
            list-style-type: none;
            padding: 0.5em 0;
            border-bottom: 1px solid #ddd;
            display: flex;
        }

        .tab > button.active {
            color: #2573a7 !important;
            border-bottom: 3px solid #2573a7;
        }

        .tab > button {
            padding: 0.5rem 1.5rem;
            color: rgba(0, 0, 0, 0.8) !important;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent implements OnInit {
    @Input() inviteHash: string;

    static profilePictureFileName = "profile_base64";

    init = false;
    profile: MatchProfile;
    questions: Question[];
    buildings: MatchStudentHousing[];
    profileSubmitted = false;
    season: Season;
    imageUrl: string;
    imageModel: ImageUploadModelI = {};
    imageObj: ImageActionDataI = {};
    hasImage = false;
    mode: "none" | "profile" | "friendSearch" | "roommates" = "profile";
    rules: Rule[] = [];
    origQuestionHiddenState = new Map<number, boolean>();

    constructor(private cd: ChangeDetectorRef) {}

    refresh = async () => {
        this.profile = await StudentHousingService.getProfile(this.inviteHash);
        let resp = await S25Util.all({
            season: SeasonsService.getSeason(this.profile.seasonId),
            buildings: StudentHousingService.getStudentBuildings(this.profile.seasonId),
            questions: StudentHousingService.getQuestions(this.profile.seasonId),
            profileImage: AttachmentService.getFileIfExists(
                3,
                this.profile.contId,
                ProfileComponent.profilePictureFileName,
                "inline",
            ),
            rules: RuleTreeService.getRules("matchForm", true, this.profile.seasonId),
        });
        if (resp.profileImage) {
            this.imageObj.imageData = await resp.profileImage.text();
            this.hasImage = !!this.imageObj.imageData;
        }
        this.rules = resp.rules;
        this.profile.desiredBuildings ??= [];
        this.profile.desiredBuildings.sort(S25Util.shallowSort("sortOrder", true));
        this.profileSubmitted = this.profile?.answers?.length > 0;
        if (this.profileSubmitted) {
            this.mode = "friendSearch"; // only show mode selector
        }
        this.season = resp.season;
        this.questions = resp.questions;
        this.questions.forEach((question) => {
            this.origQuestionHiddenState.set(question.questionId, question.hidden);
            this.profile.answers.forEach((answer) => {
                if (answer.questionId === question.questionId) {
                    question.answerId = answer.answerId;
                    if (answer.longAnswer) {
                        question.longAnswer = answer.longAnswer;
                    } else {
                        for (let opt of question.options) {
                            if (opt.option === answer.answer) {
                                if (question.questionType === "MULTISELECT") {
                                    question.answers = question.answers || [];
                                    question.answers.push(opt);
                                } else {
                                    question.singleAnswer = opt;
                                    break;
                                }
                            }
                        }
                    }
                }
            });
        });
        let desiredIdxMap = new Map<number, number>();
        for (let i = 0; i < this.profile.desiredBuildings.length; i++) {
            desiredIdxMap.set(this.profile.desiredBuildings[i].bldgId, i);
        }
        this.buildings = (resp.buildings || []).sort((a: MatchStudentHousing, b: MatchStudentHousing) => {
            if (desiredIdxMap.has(a.bldgId) && desiredIdxMap.has(b.bldgId)) {
                return desiredIdxMap.get(a.bldgId) - desiredIdxMap.get(b.bldgId);
            } else if (desiredIdxMap.has(a.bldgId)) {
                return -1;
            } else if (desiredIdxMap.has(b.bldgId)) {
                return 1;
            } else {
                return 0;
            }
        });
        this.runRules();
        this.init = true;
        this.cd.detectChanges();
    };

    save = async () => {
        if (this.hasImage) {
            await AttachmentService.delFile(3, this.profile.contId, ProfileComponent.profilePictureFileName);
        }
        let croppedImageData = await this.imageModel.getImageData();
        if (croppedImageData) {
            // save cropped image using profile id
            AttachmentService.postFile(
                3,
                this.profile.contId,
                croppedImageData,
                ProfileComponent.profilePictureFileName,
            );
        }
        await StudentHousingService.setProfileDesiredBuildings(
            this.inviteHash,
            this.buildings.map((b, idx) => {
                return {
                    contId: this.profile.contId,
                    bldgId: b.bldgId,
                    sortOrder: idx + 1,
                };
            }),
        );

        let answers: Answer[] = [];
        this.questions.forEach((q) => {
            let answer: Answer = {
                answerId: q.answerId,
                contId: this.profile.contId,
                questionId: q.questionId,
            };
            if (q.questionType === "TEXT") {
                answer.longAnswer = q.longAnswer ?? "";
                answers.push(answer);
            } else if (q.questionType === "SELECT" && q.singleAnswer.option) {
                answer.answer = q.singleAnswer.option;
                answers.push(answer);
            } else {
                // MULTISELECT
                for (let opt of q.answers) {
                    let answerOpt = Object.assign({}, answer);
                    answerOpt.answer = opt.option;
                    answers.push(answerOpt);
                }
            }
        });
        await StudentHousingService.setQuestionAnswers(this.profile.seasonId, this.inviteHash, answers);
        await StudentHousingService.updateProfile(
            this.inviteHash,
            this.profile.contId,
            this.profile.needsFirstLevel,
            this.profile.friendMsg,
        );
        this.profileSubmitted = true;
        this.setMode("friendSearch");
    };

    setMode = (mode: "none" | "profile" | "friendSearch" | "roommates" = "profile") => {
        this.mode = mode;
        this.cd.detectChanges();
    };

    clearProfilePicture = () => {
        this.imageObj.imageData = null;
        this.cd.detectChanges();
    };

    runRules = () => {
        let satRules = EventFormRuleUtil.getSatisfiedRules({
            rules: this.rules,
            matchQuestions: this.questions,
        });

        let questionsWithAction = new Set<number>();
        satRules.sync.forEach((r) => {
            const rule = S25RuleTreeUtil.parseRule(r);
            for (let [action, items] of Object.entries(rule.targets)) {
                // show matching questions
                if (action === "showQuestion") {
                    items.forEach((item) => {
                        this.questions.forEach((q) => {
                            if (q.questionId === item.itemId && !questionsWithAction.has(q.questionId)) {
                                questionsWithAction.add(q.questionId);
                                q.hidden = false;
                            }
                        });
                    });
                } else if (action === "hideQuestion") {
                    // hide matching questions
                    items.forEach((item) => {
                        this.questions.forEach((q) => {
                            if (q.questionId === item.itemId && !questionsWithAction.has(q.questionId)) {
                                questionsWithAction.add(q.questionId);
                                q.hidden = true;
                            }
                        });
                    });
                }
            }
        });

        this.questions.forEach((q) => {
            if (!questionsWithAction.has(q.questionId)) {
                q.hidden = this.origQuestionHiddenState.get(q.questionId);
            }
        });

        this.cd.detectChanges();
    };

    async ngOnInit() {
        await this.refresh();
    }
}
