import { TypeManagerDecorator } from "../../../main/type.map.service";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import {
    MatchFriendContact,
    MatchInvite,
    MatchInviteVote,
    MatchProfile,
    MatchRoommateGroup,
    StudentHousingService,
} from "../student.housing.service";
import { S25Util } from "../../../util/s25-util";

@TypeManagerDecorator("s25-ng-roommates")
@Component({
    selector: "s25-ng-roommates",
    template: `
        @if (init) {
            @if (!roommateGroup) {
                <div>
                    @if (incomingInvites.length > 0) {
                        <div class="c-margin-bottom--single incomingInvites">
                            <h3>Incoming Invites</h3>
                            @for (invite of incomingInvites; track invite) {
                                <div class="c-margin-bottom--single">
                                    <span>
                                        Invitation from {{ invite.fromContact.firstName }}
                                        {{ invite.fromContact.familyName }}
                                        @if (invite.message) {
                                            <span>&nbsp;:&nbsp;{{ invite.message }}</span>
                                        }
                                    </span>
                                    <button
                                        class="c-margin-left--half aw-button aw-button--outline"
                                        (click)="acceptIncomingInvite(invite)"
                                    >
                                        Accept
                                    </button>
                                    <button
                                        class="c-margin-left--half aw-button aw-button--danger--outline"
                                        (click)="declineIncomingInvite(invite)"
                                    >
                                        Decline
                                    </button>
                                </div>
                            }
                            <button class="aw-button aw-button--outline" (click)="refreshIncomingInvites()">
                                Refresh Incoming Invites
                            </button>
                        </div>
                    }
                    <div>
                        <h3 class="c-margin-bottom--single">Create Roommate Group</h3>
                        <label class="c-margin-bottom--single"
                            >Group Name <input class="c-input" type="text" [(ngModel)]="newGroupName"
                        /></label>
                        <p class="c-margin-bottom--single">
                            Note: there are a limited number of rooms that can accommodate more than 2 people. It is
                            therefore recommended to specify only 2 people for {{ groupCountLabel }}.
                        </p>
                        <label
                            >{{ groupCountLabel }}
                            <input class="c-input" type="number" step="1" [(ngModel)]="newGroupCount"
                        /></label>
                        <button class="c-margin-left--half aw-button aw-button--outline" (click)="createGroup()">
                            Create Group
                        </button>
                    </div>
                </div>
            }
            @if (roommateGroup) {
                <div>
                    <h3>{{ roommateGroup.name }}</h3>
                    <div class="c-margin-bottom--single">
                        <div class="c-margin-bottom--single">Members</div>
                        @for (member of roommateGroup.members; track member) {
                            <div>
                                <div>{{ member.contact.firstName }} {{ member.contact.familyName }}</div>
                            </div>
                        }
                        <button class="c-margin-top--single aw-button aw-button--outline" (click)="refreshMembers()">
                            Refresh
                        </button>
                    </div>
                    @if (roommateGroup.members.length + outgoingInvites.length < roommateGroup.desiredCount) {
                        <div class="c-margin-bottom--single">
                            <p class="c-margin-bottom--single">Invite Friend to Join</p>
                            <p class="c-margin-bottom--single">
                                Note: you may only invite as many roommates as your group allows. For example, if your
                                group allows 2 {{ groupCountLabel }}, then you may only invite 1 person.
                            </p>
                            @if (roommateGroup.members.length > 1) {
                                <p class="c-margin-bottom--single">
                                    Also note that all of your current roommates must vote in favor of this invitation
                                    before it will get sent out to the recipient. You will kick off the voting process
                                    by inviting someone below and you can view vote statuses below as well.
                                </p>
                            }
                            <select class="c-selectInput" [(ngModel)]="friendToInvite">
                                @for (friend of mutualFriends; track friend) {
                                    <option [ngValue]="friend">{{ friend.firstName }} {{ friend.lastName }}</option>
                                }
                            </select>
                            <button
                                class="c-margin-left--half aw-button aw-button--outline"
                                (click)="inviteFriendAsRoommate()"
                            >
                                Invite Friend as Roommate
                            </button>
                        </div>
                    }
                    @if (outgoingInvitesWithNoVotes.length) {
                        <div class="c-margin-bottom--single">
                            <h3>Outgoing Invites</h3>
                            @for (invite of outgoingInvitesWithNoVotes; track invite) {
                                <div class="c-margin-bottom--single">
                                    <span
                                        >Invitation for {{ invite.toContact.firstName }}
                                        {{ invite.toContact.familyName }}</span
                                    >
                                    <button
                                        class="c-margin-left--half aw-button aw-button--danger--outline"
                                        (click)="uninviteFriendAsRoommate(invite)"
                                    >
                                        Uninvite
                                    </button>
                                </div>
                            }
                            <button class="aw-button aw-button--outline" (click)="refreshOutgoingInvites()">
                                Refresh Outgoing Invites
                            </button>
                        </div>
                    }
                    @if (outgoingInvitesWithVotes.length) {
                        <div class="c-margin-bottom--single">
                            <div>Invite Votes</div>
                            @for (invite of outgoingInvitesWithVotes; track invite) {
                                <div>
                                    <div>
                                        Invitation for {{ invite.toContact.firstName }}
                                        {{ invite.toContact.familyName }}
                                    </div>
                                    <div>Status: {{ invite.status }}</div>
                                    @if (invite.status === "Pending") {
                                        @for (vote of invite.votes; track vote) {
                                            <div>
                                                <div>
                                                    {{ vote.contact.firstName }} {{ vote.contact.familyName }}:
                                                    {{ vote.status }}
                                                </div>
                                                @if (vote.contId === profile.contId) {
                                                    <div>
                                                        <button
                                                            class="aw-button aw-button--outline"
                                                            (click)="voteInFavor(vote)"
                                                        >
                                                            Approve
                                                        </button>
                                                        <button
                                                            class="aw-button aw-button--danger--outline"
                                                            (click)="voteAgainst(vote)"
                                                        >
                                                            Deny
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    }
                                </div>
                            }
                            <button class="aw-button aw-button--outline" (click)="refreshOutgoingInvites()">
                                Refresh Votes
                            </button>
                        </div>
                    }
                    <button class="c-margin-top--single aw-button aw-button--danger--outline" (click)="leaveGroup()">
                        Leave Group
                    </button>
                </div>
            }
        }
    `,
    styles: `
        .incomingInvites {
            padding-bottom: 1.25rem;
            border-bottom: 3px solid #2573a7;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoommatesComponent implements OnInit {
    @Input() profile: MatchProfile;

    init = false;
    mutualFriends: MatchFriendContact[] = [];
    outgoingInvites: MatchInvite[] = [];
    outgoingInvitesWithVotes: MatchInvite[] = [];
    outgoingInvitesWithNoVotes: MatchInvite[] = [];
    incomingInvites: MatchInvite[] = [];
    roommateGroup: MatchRoommateGroup;
    friendToInvite: MatchFriendContact;
    newGroupName: string;
    newGroupCount: number = 2;
    groupCountLabel = "Total People (Including Self)";

    constructor(private cd: ChangeDetectorRef) {}

    createGroup = async () => {
        this.roommateGroup = await StudentHousingService.createGroup(this.profile.inviteHash, {
            name: this.newGroupName,
            desiredCount: this.newGroupCount,
            seasonId: this.profile.seasonId,
            desiredBuildings: this.profile.desiredBuildings.map((b) => b.bldgId).join(";"),
            needsFirstLevel: this.profile.needsFirstLevel,
        });
        this.cd.detectChanges();
    };

    inviteFriendAsRoommate = async () => {
        await StudentHousingService.inviteFriendAsRoommate(
            this.profile.inviteHash,
            this.roommateGroup.groupInviteHash,
            this.friendToInvite.contId,
            this.profile.seasonId,
        );
        await this.refreshOutgoingInvites();
    };

    uninviteFriendAsRoommate = async (invite: MatchInvite) => {
        await StudentHousingService.uninviteFriendAsRoommate(
            this.profile.inviteHash,
            this.roommateGroup.groupInviteHash,
            this.friendToInvite.contId,
        );
        await this.refreshOutgoingInvites();
    };

    voteInFavor = (vote: MatchInviteVote) => {
        console.log("todo voteInFavor", vote);
    };

    voteAgainst = (vote: MatchInviteVote) => {
        console.log("todo voteAgainst", vote);
    };

    acceptIncomingInvite = async (invite: MatchInvite) => {
        const [_, error] = await S25Util.Maybe(
            StudentHousingService.acceptInvite(invite.matchRoommateGroupId, invite.fromContId, this.profile.inviteHash),
        );
        if (error) {
            S25Util.showError(error);
            return;
        }
        this.profile.matchRoommateGroupId = invite.matchRoommateGroupId;
        await this.refreshIncomingInvites();
        this.roommateGroup = await StudentHousingService.getRoommateGroup(this.profile.matchRoommateGroupId);
        await this.refreshMembers();
    };

    declineIncomingInvite = async (invite: MatchInvite) => {
        await StudentHousingService.declineInvite(invite.matchRoommateGroupId, this.profile.inviteHash);
        await this.refreshIncomingInvites();
    };

    leaveGroup = async () => {
        if (!this.profile.matchRoommateGroupId) {
            return;
        }
        await StudentHousingService.leaveGroup(this.profile.inviteHash, this.profile.matchRoommateGroupId);
        this.profile.matchRoommateGroupId = null;
        this.roommateGroup = null;
        await this.initComponent();
    };

    refreshMembers = async () => {
        this.roommateGroup.members.splice(0);
        this.cd.detectChanges();
        let roommateGroup = await StudentHousingService.getRoommateGroup(this.profile.matchRoommateGroupId);
        [].push.apply(this.roommateGroup.members, roommateGroup.members);
        this.cd.detectChanges();
    };

    refreshOutgoingInvites = async () => {
        this.outgoingInvites.splice(0);
        this.outgoingInvitesWithVotes.splice(0);
        this.outgoingInvitesWithNoVotes.splice(0);
        this.cd.detectChanges();
        let outgoingInvites = await StudentHousingService.getOutgoingInvites(this.profile.matchRoommateGroupId);
        [].push.apply(this.outgoingInvites, outgoingInvites);
        [].push.apply(
            this.outgoingInvitesWithVotes,
            this.outgoingInvites.filter((i) => i.votes?.length),
        );
        [].push.apply(
            this.outgoingInvitesWithNoVotes,
            this.outgoingInvites.filter((i) => !i.votes?.length),
        );
        this.cd.detectChanges();
    };

    refreshIncomingInvites = async () => {
        this.incomingInvites.splice(0);
        this.cd.detectChanges();
        let incomingInvites = await StudentHousingService.getIncomingInvites(this.profile.inviteHash);
        [].push.apply(this.incomingInvites, incomingInvites);
        this.cd.detectChanges();
    };

    initComponent = async () => {
        this.init = false;
        let friends = await StudentHousingService.getFriends(this.profile.seasonId, this.profile.inviteHash);
        this.mutualFriends = friends.filter((f) => f.incoming && f.outgoing);
        if (this.profile.matchRoommateGroupId) {
            this.roommateGroup = await StudentHousingService.getRoommateGroup(this.profile.matchRoommateGroupId);
            this.outgoingInvites = await StudentHousingService.getOutgoingInvites(this.profile.matchRoommateGroupId);
        } else {
            this.outgoingInvites = [];
        }
        this.outgoingInvitesWithVotes = this.outgoingInvites.filter((i) => i.votes?.length);
        this.outgoingInvitesWithNoVotes = this.outgoingInvites.filter((i) => !i.votes?.length);
        this.incomingInvites = await StudentHousingService.getIncomingInvites(this.profile.inviteHash);

        this.init = true;
        this.cd.detectChanges();
    };

    async ngOnInit() {
        await this.initComponent();
    }
}
