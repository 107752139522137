import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { MatchFriendContact, MatchProfile, StudentHousingService } from "../student.housing.service";
import { Table } from "../../s25-table/Table";
import { GenericTableButtonComponent } from "../../s25-table/generics/generic.table.button.component";
import { jSith } from "../../../util/jquery-replacement";
import Cell = Table.Cell;
import { S25TableComponent } from "../../s25-table/s25.table.component";
import { Rules } from "../../s25-rule-tree/s25.rule.const";
import SourceItem = Rules.SourceItem;
import { AttachmentService } from "../../../services/attachment.service";
import { ProfileComponent } from "./profile.component";

@TypeManagerDecorator("s25-ng-profile-list")
@Component({
    selector: "s25-ng-profile-list",
    template: `
        @if (init) {
            <s25-ng-checkbox
                class="ngBlock c-margin-bottom--single"
                (modelValueChange)="refreshTable()"
                [modelValue]="friendsOnly"
                >Friends Only</s25-ng-checkbox
            >

            <s25-ng-dropdown-search-criteria
                [(chosen)]="selectedQuestion"
                [type]="'matchQuestions'"
                [customFilterValue]="$any(profile.seasonId)"
            ></s25-ng-dropdown-search-criteria>

            @if (selectedQuestion?.itemTypeId) {
                <label class="c-margin-top--single">
                    Answer:
                    <select [(ngModel)]="selectedAnswer" (ngModelChange)="refreshTable()">
                        @for (child of selectedQuestion.children; track child) {
                            <option [value]="child.itemId">
                                {{ child.itemName }}
                            </option>
                        }
                    </select>
                </label>
            }

            <button class="aw-button aw-button--outline ngBlock c-margin-top--half" (click)="clearSearch()">
                Clear
            </button>

            <s25-ng-table
                [hidden]="!!viewedProfile"
                [caption]="'Roommate Search'"
                [unlimitedWidth]="true"
                [columnSortable]="false"
                [dataSource]="dataSource"
                [hasTotalRowCount]="true"
                [hasRefresh]="true"
                [showHeaderWhenNoData]="true"
            ></s25-ng-table>

            @if (viewedProfile) {
                <div class="c-margin-top--single viewedProfile">
                    <div class="c-margin-bottom--single">
                        {{ viewedProfile.contact.firstName }} {{ viewedProfile.contact.familyName }}
                    </div>
                    @if (viewedProfile.friend) {
                        @if (viewedProfile.friend.friendMsg) {
                            <div class="c-margin-bottom--single">
                                Friend Message: {{ viewedProfile.friend.friendMsg }}
                            </div>
                        }
                    }
                    @if (viewedProfile.imageUri) {
                        <div>
                            <span>Profile Picture: </span>
                            <img [src]="viewedProfile.imageUri" alt="Profile Picture" />
                        </div>
                    }
                    @for (answer of viewedProfile.answers; track answer) {
                        <div class="c-margin-bottom--single">
                            <div>{{ answer.question.question }}</div>
                            <div>{{ answer.longAnswer || answer.answer }}</div>
                        </div>
                    }
                    <a href="javascript:void(0)" (click)="backToSearchResults()">Back to Search Results</a>
                </div>
            }
        }
    `,
    styles: `
        .viewedProfile {
            padding-top: 1.25rem;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            border: 1px solid black;
            border-radius: 10px;
            margin-left: auto;
            margin-right: auto;
            max-width: 50%;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileListComponent implements OnInit {
    @Input() profile: MatchProfile;

    @ViewChild(S25TableComponent) table: S25TableComponent;

    dataSource: Table.Unpaginated;
    viewedProfile: MatchProfile;
    init = false;
    friendsOnly = false;
    selectedQuestion: SourceItem;
    selectedAnswer: string;

    constructor(private cd: ChangeDetectorRef) {}

    getRows = async () => {
        let rows: Table.Row[] = [];
        let profiles = await StudentHousingService.getProfiles(
            this.profile.seasonId,
            this.profile.contId,
            this.selectedQuestion?.itemId,
            this.selectedAnswer,
        );
        let friendContacts = await StudentHousingService.getFriends(this.profile.seasonId, this.profile.inviteHash);
        let friendMap = new Map<number, MatchFriendContact>();
        for (let friend of friendContacts) {
            friendMap.set(friend.contId, friend);
        }

        if (profiles?.length) {
            jSith.forEach(profiles, (idx, profile: MatchProfile) => {
                if (profile.contId === this.profile.contId) {
                    return;
                }

                let friend = friendMap.get(profile.contId);
                if (this.friendsOnly && !friend) {
                    return;
                }
                profile.friend = friend;

                const cells: Table.Row["cells"] = {};
                for (let i = 0; i < this.dataSource.columns.length; i++) {
                    let column = this.dataSource.columns[i];
                    let cell: Cell = {};
                    if (column.id === "add_friend") {
                        cell.inputs = {
                            disabled: friend && friend.outgoing,
                            label: column.header,
                            type: "outline",
                        };
                        cell.outputs = {
                            click: (_, row, instance) => this.addFriend(row, instance),
                        };
                    } else if (column.id === "remove_friend") {
                        cell.inputs = {
                            disabled: !friend,
                            label: column.header,
                            type: "outline",
                        };
                        cell.outputs = {
                            click: (_, row, instance) => this.removeFriend(row, instance),
                        };
                    } else if (column.id === "firstName") {
                        cell.text = profile.contact.firstName;
                    } else if (column.id === "familyName") {
                        cell.text = profile.contact.familyName;
                    } else if (column.id === "mutualFriend") {
                        cell.text = profile.friend?.incoming && profile.friend?.outgoing ? "Yes" : "No";
                    }
                    cells[column.id] = cell;
                }
                rows.push({
                    id: profile.contId,
                    name: profile.contId + "",
                    cells: cells,
                    data: profile,
                });
            });
        }
        return {
            rows: rows,
            totalRows: rows.length,
        };
    };

    addFriend = async (row: Table.Row, instance?: GenericTableButtonComponent) => {
        if (!instance.disabled) {
            instance.disabled = true;
            row.cells.remove_friend.instance.disabled = false;

            let friendProfile = row.data as MatchProfile;
            let friendContId = friendProfile.contId;
            await StudentHousingService.addFriend(this.profile.inviteHash, this.profile.seasonId, {
                seasonId: this.profile.seasonId,
                condId: this.profile.contId,
                friendContId: friendContId,
            });

            let friendContacts = await StudentHousingService.getFriends(this.profile.seasonId, this.profile.inviteHash);
            for (let friend of friendContacts) {
                if (friend.contId === friendContId && friend.incoming) {
                    friendProfile.friend = friend;
                    row.cells.mutualFriend.text = "Yes";
                    break;
                }
            }

            this.cd.detectChanges();
        }
    };

    removeFriend = async (row: Table.Row, instance?: GenericTableButtonComponent) => {
        if (!instance.disabled) {
            instance.disabled = true;
            row.cells.add_friend.instance.disabled = false;
            row.cells.mutualFriend.text = "No";
            await StudentHousingService.deleteFriend(
                this.profile.inviteHash,
                (row.data as MatchProfile).contId,
                this.profile.seasonId,
            );
            let friendProfile = row.data as MatchProfile;
            friendProfile.friend = null;
            this.cd.detectChanges();
        }
    };

    view = async (row: Table.Row, instance?: GenericTableButtonComponent) => {
        this.viewedProfile = row.data as MatchProfile;
        this.viewedProfile.imageUri = await AttachmentService.getFileTextIfExists(
            3,
            this.viewedProfile.contId,
            ProfileComponent.profilePictureFileName,
            "inline",
        );
        this.cd.detectChanges();
    };

    backToSearchResults = () => {
        this.viewedProfile = null;
        this.cd.detectChanges();
    };

    refreshTable = async () => {
        this.cd.detectChanges();
        await this.table.refresh();
        this.cd.detectChanges();
    };

    clearSearch = () => {
        this.selectedQuestion = null;
        this.selectedAnswer = null;
        return this.refreshTable();
    };

    ngOnInit() {
        this.dataSource = {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: [
                {
                    id: "firstName",
                    header: "First Name",
                },
                {
                    id: "familyName",
                    header: "Last Name",
                },
                {
                    id: "mutualFriend",
                    header: "Mutual",
                },
                GenericTableButtonComponent.Column("View", this.view),
                GenericTableButtonComponent.Column("Add Friend", this.addFriend, "outline", "min-content", false),
                GenericTableButtonComponent.Column("Remove Friend", this.removeFriend, "outline", "min-content", true),
            ],
        };

        this.init = true;
        this.cd.detectChanges();
    }
}
