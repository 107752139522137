import { TypeManagerDecorator } from "../../../main/type.map.service";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Season } from "../seasons/seasons.service";
import { CellData, RowData } from "../../s25-spreadsheet/spreadsheet.types";
import { DBContact, MatchProfile, StudentHousingService } from "../student.housing.service";
import { S25Util } from "../../../util/s25-util";

@TypeManagerDecorator("s25-ng-season-invite")
@Component({
    selector: "s25-ng-season-invite",
    template: `
        <div style="max-width: 50%">
            <p>
                Invite users to a match season by uploading a spreadsheet with the following headers and respective
                data: Internal Id, First Name, Last Name, Email, Phone.
            </p>
            <p class="c-margin-top--single c-margin-bottom--single">
                Note: the Internal Id column should be an immutable, unique, identifier from your system, such as a
                student id.
            </p>
            <p class="c-margin-top--single c-margin-bottom--single">
                Duplicate contacts will not be created with the same Internal Id, but will instead be updated if they
                have the same Internal Id.
            </p>
            <s25-ng-season-dropdown
                class="ngBlock c-margin-bottom--single"
                [(selectedSeason)]="season"
            ></s25-ng-season-dropdown>
            @if (season) {
                <s25-ng-spreadsheet
                    class="c-margin-top--single"
                    [onComplete]="onComplete"
                    [completeText]="'Invite Users'"
                ></s25-ng-spreadsheet>
            }
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeasonInviteComponent implements OnInit {
    season: Season;
    init = false;

    constructor(private cd: ChangeDetectorRef) {}

    onComplete = (rows: RowData[]) => {
        if (!rows || !rows.length) {
            return;
        }
        let contacts = this.rowsToContacts(rows);
        let profiles: MatchProfile[] = [];
        for (let contact of contacts) {
            profiles.push({
                seasonId: this.season.seasonId,
                inviteHash: S25Util.randomUUID(),
                contact: contact,
            });
        }
        console.log("profiles to add", profiles);
        StudentHousingService.addProfiles(profiles).then((addedProfiles) => {
            console.log("added profiles", addedProfiles);
        });
    };

    rowsToContacts = (rows: RowData[]) => {
        let contacts: DBContact[] = [];
        let cellNumToHeader: Map<number, String> = new Map<number, String>();

        if (!rows || !rows.length) {
            return null;
        }

        for (let r = 0; r < rows.length; r++) {
            if (!rows[r] || !rows[r].cells || !rows[r].cells.length) {
                continue;
            }

            if (r === 0) {
                // headers
                for (let c = 0; c < rows[r].cells.length; c++) {
                    let cell = rows[r].cells[c];
                    cellNumToHeader.set(c, cell.text);
                }
                continue;
            }

            let contact = this.cellsToContact(rows[r].cells, cellNumToHeader);
            contact && contacts.push(contact);
        }

        return contacts;
    };

    cellsToContact = (cells: CellData[], cellNumToHeader: Map<number, String>) => {
        let contact: DBContact = {
            addresses: [
                {
                    addrType: 4,
                },
            ],
        };

        let valueCount = 0;
        for (let c = 0; c < cells.length; c++) {
            let cell = cells[c];
            let headerName = cellNumToHeader.get(c).toLowerCase();
            if (headerName.indexOf("internal") > -1) {
                valueCount++;
                contact.internalId = cell.text;
            } else if (headerName.indexOf("first") > -1) {
                valueCount++;
                contact.firstName = cell.text;
            } else if (headerName.indexOf("last") > -1) {
                valueCount++;
                contact.familyName = cell.text;
            } else if (headerName.indexOf("email") > -1) {
                valueCount++;
                contact.addresses[0].email = cell.text;
            } else if (headerName.indexOf("phone") > -1) {
                valueCount++;
                contact.addresses[0].phone = cell.text;
            }
        }

        if (valueCount === 5) {
            return contact;
        }
        return null;
    };

    ngOnInit() {
        this.init = true;
        this.cd.detectChanges();
    }
}
