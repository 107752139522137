import { TypeManagerDecorator } from "../../../main/type.map.service";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../../util/s25-util";
import { Question, StudentHousingService } from "../student.housing.service";
import { Rules } from "../../s25-rule-tree/s25.rule.const";

@TypeManagerDecorator("s25-ng-match-question")
@Component({
    selector: "s25-ng-match-question",
    template: `
        @for (question of questions; track question; let idx = $index) {
            <div class="c-margin-bottom--double ngQuestion">
                <div class="c-margin-bottom--single">
                    <label
                        >Question:
                        <input class="c-input" type="text" [(ngModel)]="question.question" name="question-{{ idx }}" />
                    </label>
                    <s25-ng-checkbox class="c-margin-left--half" [(modelValue)]="question.hidden"
                        >Hidden</s25-ng-checkbox
                    >
                </div>
                <select
                    class="cn-form__control c-margin-bottom--single ngBlock"
                    [(ngModel)]="question.questionType"
                    (ngModelChange)="onTypeChange(question)"
                >
                    <option value="SELECT">Select</option>
                    <option value="MULTISELECT">Multi-Select</option>
                    <option value="TEXT">Text</option>
                </select>
                @if (question.questionType !== "TEXT") {
                    <div class="c-margin-bottom--single">
                        <div s25-ng-dnd-sortable [items]="question.options">
                            @for (opt of question.options; track opt; let idx = $index) {
                                <div class="c-margin-bottom--half" s25-ng-dnd-sortable-item [index]="idx">
                                    <s25-ng-drag-handle></s25-ng-drag-handle>
                                    <label class="ngInlineBlock"
                                        >Answer Choice:
                                        <input
                                            class="c-input"
                                            type="text"
                                            [(ngModel)]="opt.option"
                                            name="answer-{{ idx }}"
                                    /></label>
                                    <button
                                        class="c-margin-left--single aw-button aw-button--danger--outline"
                                        (click)="removeAnswer(question, opt)"
                                    >
                                        Remove
                                    </button>
                                </div>
                            }
                        </div>
                        <label class="ngInlineBlock"
                            >New Answer:
                            <input
                                class="c-input"
                                type="text"
                                [(ngModel)]="question.newAnswer.text"
                                name="new-{{ idx }}"
                        /></label>
                        <button
                            class="c-margin-left--single aw-button aw-button--outline"
                            (click)="addAnswer(question)"
                        >
                            Add
                        </button>
                    </div>
                }
                <div class="ngInlineBlock">
                    <button class="aw-button aw-button--outline c-margin-right--single" (click)="move(question, -1)">
                        Move Up
                    </button>
                    <button class="aw-button aw-button--outline  c-margin-right--single" (click)="move(question, 1)">
                        Move Down
                    </button>
                    <button class="aw-button aw-button--danger--outline" (click)="removeQuestion(question)">
                        Remove
                    </button>
                </div>
            </div>
        }

        <button class="aw-button aw-button--outline c-margin-right--single" (click)="addQuestion()">
            Add Question
        </button>

        <s25-ng-rule-tree
            class="ngBlock c-margin-top--single"
            [category]="'matchForm'"
            [subCategory]="subCategory"
            [conditionFilterMap]="conditionFilterMap"
        ></s25-ng-rule-tree>

        <button class="aw-button aw-button--primary" (click)="saveAll()">Save</button>
    `,
    styles: `
        .ngQuestion {
            border-bottom: 1px solid black;
            padding-bottom: 20px;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionComponent implements OnInit {
    @Input() seasonId: number;

    questions: Question[];
    subCategory: string;
    conditionFilterMap: Rules.ConditionFilterMap;
    init = false;

    constructor(private cd: ChangeDetectorRef) {}

    onTypeChange = (question: Question) => {
        this.cd.detectChanges();
    };

    addAnswer = (question: Question) => {
        let answer = question.newAnswer.text;
        if (answer) {
            question.newAnswer.text = "";
            question.options.push({
                option: answer,
                questionId: question.questionId,
                sortOrder: question.options.length + 1,
            });
            let idx = this.questions.indexOf(question);
            this.questions.splice(idx, 1);
            this.cd.detectChanges();
            this.questions.splice(idx, 0, question);
            this.cd.detectChanges();
        }
    };

    removeAnswer = (question: Question, answer: { option?: string }) => {
        for (let i = question.options.length - 1; i >= 0; i--) {
            if (question.options[i].option === answer.option) {
                question.options.splice(i, 1);
            }
        }
        this.cd.detectChanges();
    };

    addQuestion = () => {
        this.questions.push({
            question: "New Question! Remember to Save When Finished.",
            questionType: "SELECT",
            options: [{ option: "Yes" }, { option: "No" }],
            newAnswer: { text: "" },
        });
        this.cd.detectChanges();
    };

    removeQuestion = (question: Question) => {
        this.questions.splice(this.questions.indexOf(question), 1);
        this.cd.detectChanges();
        return;
    };

    move = (question: Question, dir: number) => {
        let idx = this.questions.indexOf(question);

        if (dir === 1 && idx === this.questions.length - 1) {
            return;
        }

        if (dir === -1 && idx === 0) {
            return;
        }

        let temp = this.questions[idx + dir];
        this.questions[idx + dir] = question;
        this.questions[idx] = temp;

        for (let i = 0; i < this.questions.length; i++) {
            this.questions[i].sortOrder = i;
        }

        this.questions.sort(S25Util.shallowSort("sortOrder", true));
        this.cd.detectChanges();
    };

    refresh = () => {
        S25Util.all({
            questions: StudentHousingService.getQuestions(this.seasonId),
        }).then((resp) => {
            for (let question of resp.questions) {
                question.newAnswer = { text: "" };
            }
            this.questions = resp.questions;
            this.init = true;
            this.cd.detectChanges();
        });
    };

    saveAll = async () => {
        await StudentHousingService.replaceSeasonQuestions(this.seasonId, this.questions);
    };

    ngOnInit() {
        this.subCategory = this.seasonId + "";
        this.conditionFilterMap = { matchQuestions: "" + this.seasonId, matchQuestionsAll: "" + this.seasonId };
        this.refresh();
    }
}
